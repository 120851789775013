#footer {
  background-color: #78797f;
  color: #ffffff;
  display: block;
  min-width: 100vw;
  text-align: center;
  /* margin-top: 100px; */
}

@media (max-width: 576px) {
  #contactus {
    margin-top: 50px;
  }
}

.main-page-card {
  border-radius: 4%;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.3s ease-in-out;
}

.main-page-card:hover {
  transform: translateY(-5%);
  box-shadow: 5px 10px 10px 5px rgba(0, 0, 0, 0.4);
}

.main-page-card img {
  height: 15vmax;
  width: 100%;
}

.main-page-text,
.text-blue {
  color: #05386b;
}

@media (max-width: 576px) {
  .main-page-text {
    font-size: 1rem;
  }
}

.main-page-title {
  font-size: 4vw;
  letter-spacing: 1.5vw;
}

.main-page-subtitle {
  font-size: 2.3vw;
}

@media (max-width: 576px) {
  .main-page-title {
    font-size: 9vw;
    letter-spacing: 3.5vw;
  }
  .main-page-subtitle {
    font-size: 4.7vw;
  }
}

/* basic page */

.small-nav-btn {
  line-height: 34px;
}

.top-navbar {
  z-index: 20;
}

.nav-logo-text {
  font-size: 1.3rem;
  letter-spacing: 0.3vw;
}

.nav-logo-subtext {
  font-size: 0.5rem;
}

.navbar-brand img {
  width: 4rem;
}

@media (max-width: 576px) {
  .loginButton {
    font-size: 14px;
    padding: 0.4rem 1rem !important;
  }
}

.navlinks {
  transform: translateY(25%);
}

.basic-navbar {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

.nav-link,
.text-bold {
  font-weight: 500;
}

.lang-option {
  color: #999999;
}

.lang-active {
  color: #06386b;
}

.circle {
  height: 40px;
  width: 40px;
  position: fixed;
  z-index: 10;
  top: 77px;
  right: 2%;
  border-radius: 100%;
  background-color: #05386b;
  line-height: 40px;
  text-align: center;
}

.side-navbar {
  z-index: 6;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
}

.side-bg {
  position: fixed;
  top: 0px;
  right: 70vw;
  width: 30vw;
  height: 100vh;
  background-color: rgba(80, 80, 80, 0.5);
}

.side-navbar-area {
  z-index: 7;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 70vw;
  height: 100vh;
  z-index: 10;
  background-color: #fff;
}

.side-navbar-text-area {
  z-index: 8;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 70vw;
  height: 100vh;
  overflow-y: scroll;
  padding-top: 90px;
  padding-bottom: 20px;
}

@media (max-width: 415px) {
  .circle {
    top: 120px;
  }
}

.basic-1 {
  width: 90%;
}

/* jumbotron */

.jumbotron-container {
  padding: 50px 0;
}

@media (max-width: 576px) {
  .jumbotron-container {
    padding: 20px 0;
  }
}

.alert-danger {
  color: #e47979;
}

.alert-danger-text {
  color: #717171;
}

/* News */

#news {
  background-color: #9fcecd;
}

.news-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, 0.3);
}

.news-item:hover {
  background-color: #fffaf3;
}

@media (max-width: 576px) {
  .news-item-text {
    max-width: 450px;
  }
}

.news-item-arrow {
  width: 20px;
  height: 20px;
  background-color: #06386b;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  margin: 0;
}

.news-text {
  color: #717171;
}

/* service */

#service {
  background-color: #efefef;
}

.service-img {
  width: 50px;
}

.service-card-text {
  font-size: 20px;
}

.service-cards2 {
  position: relative;
  padding-top: 20px;
  height: 100%;
}

.service-content2 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.service-content2 p {
  font-size: 16px;
}

.service-content2 img {
  height: 100px;
  object-fit: scale-down;
}

@media (max-width: 576px) {
  .service-card-text {
    font-size: 11px;
  }
  .service-content2 p {
    font-size: 12px;
  }
}

/* team */

.card {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-height: 398px;
}

.card-top {
  background-color: #9fcecd;
  border-radius: 10px 10px 0 0;
}

.circle-img {
  width: 159px;
  height: 159px;
  overflow: hidden;
}

.circle-img img {
  height: 100%;
  object-fit: cover;
}

.badge-success {
  background-color: #9fcecd;
}

.badge-light {
  background-color: #fff;
}

/* contact us */

#contactus {
  background-color: #efefef;
}

/* news page */

.news-btn {
  border-radius: 5px;
  border: 0;
}

/* service page */

.vl {
  border-left: 2px solid #d8d8d8;
  min-height: 95px;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  top: 0;
}

.sw-100 {
  width: 100%;
}

@media (max-width: 576px) {
  .sw-100 {
    width: 50%;
  }
}

.smal-title {
  color: #78797f;
  font-size: 14px;
}

.s-rule-box,
.q-box {
  background-color: white;
  width: 100%;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.s-rule-box .fas {
  line-height: 30px;
}

.pagination-front {
  background-color: rgba(242, 242, 242, 0.4995);
  border-radius: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 100%;
}

.p-num-li {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.p-num-li:hover {
  background-color: #efefef;
}

.p-num-a {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
}

.p-active,
.p-active:hover {
  background-color: #9fcecd;
  color: white;
}

.p-active-db,
.p-active-db:hover {
  background-color: #06386b;
  color: white;
}

/* question page */

.q-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  color: white;
}

/* schedule page */

.schedule-timelabel {
  font-size: 14px;
  max-width: 14%;
}
@media (max-width: 576px) {
  .schedule-timelabel {
    font-size: 10px;
    padding-left: 6px;
    padding-right: 5px;
  }
}

.schedule-top {
  width: 100%;
  max-width: 224px;
}
@media (max-width: 576px) {
  .schedule-top {
    max-width: 168px;
  }
}

.schedule-btn {
  width: 100%;
  color: #9fcecd;
  background-color: white;
  border: 1px solid #9fcecd;
  cursor: pointer;
  font-size: 16px;
}
.schedule-btn:first-of-type {
  border-radius: 5px 0 0 5px;
}
.schedule-btn:last-of-type {
  border-radius: 0 5px 5px 0;
}
@media (max-width: 576px) {
  .schedule-btn {
    font-size: 12px;
  }
}

.schedule-btn-active {
  background-color: #9fcecd;
  color: white;
}

.schedule-tips {
  font-size: 16px;
  letter-spacing: 1.33333px;
}
@media (max-width: 576px) {
  .schedule-tips {
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.small-course-name {
  border-radius: 5px;
  cursor: pointer;
  font-size: 16.8px;
}

@media (max-width: 576px) {
  .small-course-name {
    font-size: 10px;
    letter-spacing: 0.833333px;
  }
}

.flex-equal {
  flex-grow: 1;
  flex-basis: 0;
}

.course-div {
  border-bottom: 1px solid #c0c0c0;
}

.schedule-course-row {
  min-height: 85px;
}

.schedule-header {
  border-bottom: 3px solid #c0c0c0;
}

@media (max-width: 576px) {
  .schedule-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.schedule-date {
  background-color: #c0c0c0;
  border-radius: 20px;
  display: inline-block;
  font-size: 16px;
}

.schedule-date-word {
  font-size: 20px;
}

@media (max-width: 576px) {
  .schedule-date {
    font-size: 12px;
  }

  .schedule-date-word {
    font-size: 12px;
  }
}

.today-red {
  background-color: rgba(228, 121, 121, 0.3);
}

.today-red p {
  background-color: #e47979;
}

.over-today p {
  background-color: #9fcecd;
}

.today-head .schedule-date {
  background-color: #e47979;
}

.today-head p:last-child {
  color: #e47979;
}

.today-head {
  border-bottom: 3px solid #e47979;
}

.over-today-head .schedule-date {
  background-color: #9fcecd;
}

.over-today-head p:last-child {
  color: #9fcecd;
}

.over-today-head {
  border-bottom: 3px solid #9fcecd;
}

/* reserve page */

.reserve-box,
.radius-border {
  border-radius: 5px;
}

.reserve-box label {
  color: #717171;
}

.i-want-reserve-btn {
  background: #06386b;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.submit-btn {
  width: 80%;
  max-width: 340px;
  height: 62px;
  background-color: #06386b;
  border: 0;
  border-radius: 5px;
}

.submit-btn:disabled {
  background-color: #c0c0c0;
}

.red-star {
  color: #e47979;
  font-size: 7px;
}

.custom-file,
.custom-file input {
  min-height: 50px;
}

.custom-file-label {
  background-color: #9fcecd;
  border-radius: 5px;
  color: white !important;
}

.custom-file-label::after {
  display: none;
}

.custom-file-label-db {
  background: #06386b;
  box-shadow: 0px 0px 4px #bfbfbf;
  max-width: 14rem;
  text-align: center;
}

.selected-file {
  border: 1px solid #e7e7e7;
  border-top: 5px solid #9fcecd;
}

.reserve-success-icon {
  color: white;
  background-color: #9fcecd;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  line-height: 80px;
  font-size: 50px;
}

.reserve-record-tobdy td {
  vertical-align: middle;
}

/* record page */

.front .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(159, 206, 205, 0.3);
}

#record table,
.mobile-table,
.page_body {
  background-color: white;
  box-shadow: 0px 0px 4px #bfbfbf;
  border-radius: 10px;
}

.record-confirm {
  background-color: #06386b;
  color: white;
  border-radius: 5px;
}

.mobile-table-item {
  display: flex;
  flex-direction: column;
}

.mobile-table-item div:not(.mobile-table-item div:first-child) {
  margin-top: 7px;
}

.mobile-table-item:first-child {
  border-radius: 10px 10px 0 0;
}

.mobile-table-item:last-child {
  border-radius: 0 0 10px 10px;
}

.mobile-table-item:nth-of-type(odd) {
  background-color: rgba(159, 206, 205, 0.3);
}

/* profile page */

.left-col {
  border-radius: 10px 0 0 10px;
}

@media (max-width: 576px) {
  .left-col {
    border-radius: 10px 10px 0 0;
  }
}

.edit-circle-div {
  position: relative;
  width: 100%;
  height: 80px;
}

.edit-circle {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  font-size: 20px;
  cursor: pointer;
}

.page_body input,
.page_body textarea {
  background: rgba(159, 206, 205, 0.2);
  border-radius: 5px;
  border: 0;
}

.page_body input:focus,
.page_body textarea:focus {
  background: rgba(159, 206, 205, 0.2);
}

/* download page */
.download-area {
  background: #ffffff;
  border-radius: 10px;
  border: 0.5px solid black;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 22px;
  padding-right: 22px;
}

.blue-circle {
  background: #05386b;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
}

.blue-circle i {
  color: white;
  line-height: 50px;
  font-size: 20px;
}

.download-area span {
  line-height: 50px;
}

.salary-dropdown button {
  width: 100%;
  padding: 10px;
}

.salary-dropdown .dropdown-menu {
  width: 100%;
  overflow-y: scroll;
  max-height: 20vh;
}
