body {
  margin: 0;
  font-family: 'Noto Sans TC', 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  letter-spacing: 1.66667px;
}

button {
  letter-spacing: 1.66667px;
}

.btn-sm {
  padding: 0.4rem 0 !important;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

.text-light {
  color: #ffffff !important;
}

.text-dark {
  color: #000000 !important;
}

.text-green {
  color: #9fcecc !important;
}

.text-darkgreen {
  color: #4caf50 !important;
}

.text-grey {
  color: #717171 !important;
}

.text-darkgrey {
  color: #c0c0c0 !important;
}

.text-blue {
  color: #05386b !important;
}

.text-red {
  color: #e47979;
}

.size-1 {
  font-size: 35px;
}

.size-2 {
  font-size: 30px;
}

.size-3 {
  font-size: 20px;
}

.size-4 {
  font-size: 16px;
}

.size-5 {
  font-size: 14px;
}

.size-6 {
  font-size: 12px;
}

.border-right {
  border-right: 1px solid #717171 !important;
}

@media (max-width: 576px) {
  .size-1 {
    font-size: 27.625px;
  }
  .size-2 {
    font-size: 23.25px;
  }
  .size-3 {
    font-size: 15.5px;
  }
  .size-4 {
    font-size: 14px;
  }
  .size-5 {
    font-size: 13.25px;
  }
  .size-6 {
    font-size: 11.5px;
  }
}

.btn {
  padding: 8px 36px;
  min-width: 117px;
  color: white;
  border-radius: 50px;
  border: 0;
}

.btn:hover {
  color: white;
}

.btn-orange {
  background-color: #facf83;
}

.btn-orange:hover {
  background-color: #e2a63a;
}

.btn-outline-orange {
  background-color: #fff;
  color: #facf82;
  border: 3px solid #facf82;
}

.btn-outline-orange:hover,
.btn-outline-orange.active {
  background-color: #facf82;
  color: #fff;
}

.btn-green {
  background-color: #9fcecd;
}

.btn-green:hover,
.btn-success:hover {
  background-color: #468583;
}

.db-btn {
  width: 100%;
  min-width: 117px;
  border: 0;
  height: 40px;
  border-radius: 5px;
}

a.db-btn:hover {
  color: white !important;
}

.db-btn:disabled,
.db-btn.disabled {
  background: #c0c0c0;
  box-shadow: 0px 0px 4px #bfbfbf;
}

.db-btn-primary {
  background-color: #06386b;
  color: white;
  box-shadow: 0px 0px 4px #bfbfbf;
  border-radius: 5px;
}

.db-dropdown {
  border: 0;
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 4px #bfbfbf;
  border-radius: 5px;
  width: 150px;
}

.bg-darkblue {
  background-color: #06386b;
}

.bg-blue {
  background-color: #9fcecc;
}

.bg-lightblue {
  background-color: #f5faff;
}

.bg-grey {
  background-color: #efefef;
}

.bg-darkgrey {
  background-color: #c0c0c0;
}

.bg-green {
  background-color: #9fcecd;
}

.bg-red {
  background-color: #e47979;
}

.bg-yellow {
  background-color: #facf82;
}

.db-bg-darkgrey {
  background-color: #dedede;
}

.absolute-bg {
  position: fixed;
  background-color: #fafafa;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -100;
}

.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
