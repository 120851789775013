.wave-section {
  position: relative;
}

.wave-section::before {
  content: '';
  position: absolute;
  display: block;
  background-color: #9fcecd;
  border-radius: 50%;
  width: 50%;
  height: 20vw;
  left: 0px;
  top: -9vw;
  z-index: -1;
}
