// Override default variables before the import
$success: #9fcecd;
$danger: #e47979;
$primary: #06386b;
$orange: #facf83;
$table-accent-bg: rgba(159, 206, 205, 0.3);
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
}
